// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('invoice/getData', async (params, { getState }) => {
  const invDates = getState().invoice.invDates
  const dates = !Array.isArray(invDates) || invDates.length === 1 ? [invDates, invDates] : invDates
  const response = await axios.post('/sale/pagelist', { ...params, dates })
  return response
})

export const sendInvoiceEmail = createAsyncThunk('invoice/sendInvoiceEmail', async (data) => {
  await axios.post('/invoice/send', data)
})

export const deleteRow = createAsyncThunk('invoice/deleteRow', async ({ id }, { dispatch }) => {
  const response = await axios.post('/sale/delete', { id })
  await axios.post('/particular/delete', { sale_id: id })
  await axios.post('/subscription/delete', { sale_id: id })
  await dispatch(getData())
  return response
})

export const cancelInvoice = createAsyncThunk('invoice/cancelInvoice', async (id) => {
  const response = await axios.post('/sale/cancelinvoice', { id })
  return response
})

export const handlePayment = createAsyncThunk('invoice/handlePayment', async ({id, data}) => {
  const response = await axios.post(`/sale/payments/${id}`, data)
  return response
})

export const appInvoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    loading: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    tableData: {
      allData: [],
      data: [],
      totalPages: 0,
      params: {}
    },
    invDates: [new Date(), new Date()],
    invStatics: { count: 0, value: 0 }
  },
  reducers: {
    setDate: (state, action) => {
      state.invDates = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.tableData = action?.payload?.data?.data || {}
        state.invStatics = action?.payload?.data?.params || { count: 0, value: 0 }
      })
      .addCase(cancelInvoice.pending, (state) => {
        state.loading = true
      })
      .addCase(cancelInvoice.rejected, (state) => {
        state.loading = false
      })
      .addCase(cancelInvoice.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(handlePayment.pending, (state) => {
        state.loading = true
      })
      .addCase(handlePayment.rejected, (state) => {
        state.loading = false
      })
      .addCase(handlePayment.fulfilled, (state) => {
        state.loading = false
      })
  }
})

export const actions = appInvoiceSlice.actions

export default appInvoiceSlice.reducer
