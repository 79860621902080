import strings from "../configs/strings"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: '2-digit', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const toastMessage = (message) => {
  return (
    <div className='toastify-body'>
      <span>
        {message || strings.ERROR}
      </span>
    </div>
  )
}

export const nameViewer = (data) => {
  if (!data || (!data?.name_en && !data.name_ar)) return ""
  const { name_en, name_ar } = data
  const isAR = localStorage.getItem("i18nextLng") === 'ar'
  const name = isAR ? (name_ar || name_en || "") : (name_en || name_ar || "")
  return name
}

export const validateMobile = (number) => {
  let error = null
  const stringMobile = String(number)
  const isStartingZero = stringMobile.substring(0, 1) === "0"
  const isStartingCode = stringMobile.substring(0, 3) === "966"
  const mobile = stringMobile.slice(-9)
  const isStartingZero2 = mobile.substring(0, 1) === "0"

  switch (stringMobile.length) {
    case (9):
      error = isStartingZero ? 'Please enter valid mobile number' : null
      break

    case (10):
      error = isStartingZero ? null : 'Plese enter 9 digit mobile number or with 0 prefix'
      break

    case (12):
      error = !isStartingCode ? 'Plese enter 9 digit mobile number or with 966 prefix' : isStartingZero2 ? 'Plese enter a valid 9 digit mobile number' : null
      break

    default:
      error = 'Plese enter a valid 9 digit mobile number'
      break
  }

  return { mobile, error }
}

export const isAdmin = () => {
  const userData = localStorage.getItem("userData")
  const role = (userData ? JSON.parse(userData)?.role : 'na').toLowerCase()
  return role === 'admin' || role === 'dev'
}

export const scrollToTop = () => [
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
]

const getMarginMinute = ({ margin_minutes, pendingMiliseconds }) => {
  if (pendingMiliseconds > 0) return 0
  const isHavingMultiple = String(margin_minutes).includes(",")
  if (!isHavingMultiple) {
    return margin_minutes
  }
  //[5,10,5,0]
  const marginMinutesArray = margin_minutes.split(",").map(v => v * 1)
  if (!marginMinutesArray?.length) {
    return 0
  }

  const lastHourPrice = marginMinutesArray.slice(-1)[0]
  let value = 0
  let hour = 0
  const overTimeHours = Math.ceil(Math.abs(pendingMiliseconds) / 3600000)
  const myHours = Math.min(process.env.MAX_OVERTIME_HOURS || 8, overTimeHours)

  do {
    value += hour > marginMinutesArray.length - 1 ? lastHourPrice : marginMinutesArray[hour]
    hour++
  }
  while (hour < myHours)
  return value
}

export const getPendingMS = ({ daily_limits, login, margin_minutes }) => {
  const allowedMiliseconds = daily_limits * 3600 * 1000
  const usedInThisSession = new Date().getTime() - new Date(login).getTime()
  const usedInOtherSession = 0
  const pendingMiliseconds = allowedMiliseconds - usedInThisSession - usedInOtherSession
  const withBufferPendingMS = pendingMiliseconds + (getMarginMinute({
    margin_minutes,
    pendingMiliseconds
  }) * 60 * 1000)

  return {
    pendingMiliseconds,
    withBufferPendingMS,
    allowedMiliseconds: allowedMiliseconds + getMarginMinute(margin_minutes, usedInThisSession)
  }
}